export const oesteVindiNovaAssinatura = {
  title: "Vindi - Novas assinaturas",
  tabs: {
    operation: {
      label: "Histórico criação",
      title: "Histórico criação",
      description: "Últimos assinaturas criadas.",
      cleanOption: true,
      downloadOption: true,
      columns: [
        { label: "Registros Totais", column: "renovacoes_totais" },
        { label: "Sucesso", column: "sucesso" },
        { label: "Erro", column: "error" },
        {
          label: "Início Criação",
          column: "inicio_renovacao",
          format: "date"
        },
        { label: "Fim Criação", column: "fim_renovacao", format: "date" },
        { label: "Status", column: "status" }
      ],
      actions: {
        run: {
          active: false,
          label: "Executar"
        }
      }
    },
    feature1: {
      active: true,
      label: "Últimos registros",
      title: "Últimos registros",
      description: "Confira as últimas atualizações dos registros.",
      cleanOption: true,
      downloadOption: false,
      columns: [
        { label: "ID Plano", column: "planId", align: "left" },
        { label: "Método Pagamento", column: "paymentMethod" },
        { label: "Cliente", column: "customerName", align: "left" },
        { label: "Email Cliente", column: "customerEmail" },
        { label: "Code Cliente", column: "customerCode" },
        {
          label: "ID Cliente",
          column: "customerId"
        }
      ],
      actions: {
        run: {
          active: true,
          label: "Executar"
        },
        unitaryAction: {
          active: false,
          label: "Renovação unitária"
        },
        uploader: {
          active: true,
          label: "Upload arquivos CSV"
        }
      }
    }
  }
};
